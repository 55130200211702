html,
body,
#root {
  height: 100%;
}

.caches-table {
}

.pf-c-brand {
}
.pf-c-nav {
  /*--pf-c-nav--m-dark__item--m-current--BackgroundColor: #004B95;*/
}

.status-label {
  padding-bottom: var(--pf-global--spacer--sm);
}

.status-icon {
  padding-left: var(--pf-global--spacer--md);
  padding-right: var(--pf-global--spacer--sm);
  padding-bottom: var(--pf-global--spacer--sm);
}

.pf-c-dropdown__menu-item {
  cursor: pointer;
}

.alert-message.pf-c-alert.pf-m-inline {
  --pf-c-alert--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-alert--BorderTopWidth: 0;
  --pf-c-alert--PaddingTop: 0;
}

.failed-link.pf-c-button {
  --pf-c-button--m-link--Color: var(--pf-global--danger-color--100);
  --pf-c-button--m-link--hover--Color: var(--pf-global--danger-color--200);
  --pf-c-button--m-link--focus--Color: var(--pf-global--danger-color--200);
  --pf-c-button--m-link--active--Color: var(--pf-global--danger-color--200);
}

.pf-c-toolbar {
  padding-bottom: 0;
}

.pf-c-table__action {
  text-align: right;
}
